




































































import { RouterNames } from "@/router/routernames";
import { incidenciaAllDto } from "@/shared/dtos/incidencias/incidenciaAllDto";
import { invetario } from "@/shared/dtos/invetario";
import { MessageService } from "@/shared/services/message-service";
import invetarioModule from "@/store/modules/invetario-module";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    IncidenciasFormulario: () =>
      import("@/views/proyectos/incidencias/incidenciasFormulario.vue"),
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
  },
})
export default class SubinventarioLista extends Vue {
  created() {
    this.GetInventario();
  }

  public get inventario() {
    return invetarioModule.invetarios.filter(
      (x) => x.id_sub_inventario === Number.parseInt(this.$route.params.id)
    );
  }

  public GetInventario() {
    return invetarioModule.getinvetarios();
  }
  editar(obj: invetario) {
    this.$router.push({
      name: RouterNames.inventario_formulario,
      params: { id: obj.id },
      query: { sub_inventario: "1" },
    });
  }

  add() {
    this.$router.push({
      name: RouterNames.inventario_formulario,
      params: { id: "-1" },
      query: { id_sub_inventario: this.$route.params.id, sub_inventario: "1" },
    });
  }

  eliminar(obj: invetario) {
    MessageService.confirm(
      "¿Seguro que desea eliminar el Subinventario?",
      "Eliminar",
      (cb: boolean) => {
        if (cb) {
          invetarioModule.eliminarinvetario(obj).then(() => {
            this.GetInventario();
          });
        }
      }
    );
  }
}
