var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"grey--text subtitle-1"},[_vm._v("Piezas sustituidas del inventario")]),_c('v-spacer'),_c('Xbtn',{attrs:{"icon":"","color":"primary","permiso":"write_inventario"},on:{"click":function($event){return _vm.add()}}},[_c('v-icon',[_vm._v("add")])],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":[
      {
        text: 'Nombre',
        value: 'nombre',
      },
      {
        text: 'Modelo',
        value: 'modelo',
      },
      {
        text: 'Nº Serie / REF',
        value: 'n_serie',
      },
      {
        text: 'Stock',
        value: 'stock',
      },
      {
        text: 'Archivado',
        value: 'activo',
      },
      {
        text: '',
        value: 'actions',
        align: 'end',
      } ],"items":_vm.inventario},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('Xbtn',{attrs:{"icon":"","color":"primary","permiso":"update_inventario"},on:{"click":function($event){return _vm.editar(item)}}},[_c('v-icon',[_vm._v("edit")])],1),_c('Xbtn',{attrs:{"icon":"","color":"red","permiso":"delete_inventario"},on:{"click":function($event){return _vm.eliminar(item)}}},[_c('v-icon',[_vm._v("delete")])],1)]}},{key:"item.activo",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","color":!item.activo ? 'green' : 'red',"dark":""}},[_vm._v(_vm._s(!item.activo ? "Sí" : "No"))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }